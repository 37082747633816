<template>
<!--  <index-page v-if="width>750"/>-->
  <index-pc-page v-if="width>750"/>
  <index-mobile-page v-else/>
</template>

<script setup>
// import IndexPage from './pages/IndexPage.vue'
import {onMounted, onUnmounted, ref} from "vue";
import IndexMobilePage from "@/pages/indexMobilePage.vue";
import IndexPcPage from "@/pages/IndexPcPage.vue";

const width = ref();
const resize = () => {
  width.value = window.innerWidth;
  document.documentElement.style.setProperty("--vh", window.innerHeight + "px");
}
resize()
onMounted(() => {
  window.addEventListener('resize', resize)
})
onUnmounted(() => {
      window.removeEventListener('resize', resize)
    }
)
</script>

<style>
:root {
  --vh: 100vh
}

html, body, div {
  padding: 0;
  margin: 0;
}

html {
  overflow: auto;
  min-height: 798px;
}

body {
  overflow: hidden;
  min-height: 798px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow: hidden;
  //min-width: 960px;
}

#app::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

@media (max-width: 775px) {
  html {
    font-size: 1.33vw;
  }
}
</style>
