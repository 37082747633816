<template>
  <div class="home">
    <audio ref="audio-bg" loop controls style="position:absolute;z-index:1;top: .6rem;left: 1rem;display: none">
      <source src="@/assets/audio/bg.mp3" type="audio/mpeg"/>
    </audio>
    <div class="icon-sound-box" @click="playSound">
      <img v-if="audioPlayed" src="@/assets/icons/svg/sound.svg" class="icon-sound"/>
      <img v-else src="@/assets/icons/svg/music-on.svg" class="icon-sound"/>
    </div>
<!--    <img src="@/assets/images/bg.jpg" class="img-bg"/>-->
    <div class="page-inner">
      <img class="title" src="@/assets/images/title.png" alt="">
      <div class="time" :class="{before:!enter}">
        <div v-if="enter" class="border time-border">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div v-if="enter" class="border time-border2">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div v-if="enter" class="border time-border3">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div v-if="enter" class="time-wp time1-wp">
          <span class="text">{{ day }}</span>
        </div>
        <div v-if="enter" class="time-wp gap">
          <span class="text">天</span>
        </div>
        <div v-if="enter" class="time-wp time2-wp">
          <span class="text">{{ hour }}</span>
        </div>
        <div v-if="enter" class="time-wp gap1">
          <span class="text">:</span>
        </div>
        <div v-if="enter" class="time-wp time3-wp">
          <span class="text">{{ minute }}</span>
        </div>
        <div v-if="enter" class="time-wp gap1">
          <span class="text">:</span>
        </div>
        <div v-if="enter" class="time-wp time4-wp">
          <span class="text">{{ second }}</span>
        </div>
        <div class="time-before" :class="{hidden:enter}" @click="enterHandle"></div>
      </div>
      <img class="des" src="@/assets/images/des.png" alt="">
    </div>
  </div>
</template>

<script>
import {startAt} from '@/data/date.json';

export default {
  name: 'IndexPcPage',
  props: {},
  data: function () {
    return {
      interval: null,
      day: "0",
      hour: "00",
      minute: "00",
      second: "00",
      audioPlayed: false,
      enter: false
    }
  },
  created() {
    this.interval = setInterval(this.calcDate, 1000);
  },
  unmounted() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    enterHandle() {
      this.enter = true
      this.audioPlayed = true;
      this.$refs["audio-bg"].play();
    },
    playSound() {
      if (!this.audioPlayed) {
        this.audioPlayed = true;
        console.log(this.$refs["audio-bg"])
        this.$refs["audio-bg"].play();
      } else {
        this.audioPlayed = false;
        this.$refs["audio-bg"].pause();
      }
    },
    calcDate: function () {
      let startTimestamp = parseInt(Date.parse(startAt) / 1000);
      let now = parseInt(Date.now() / 1000);
      let diffSec = 0;
      if (startTimestamp > now) {
        diffSec = startTimestamp - now;
      } else {
        if (this.interval) {
          clearInterval();
        }
      }

      this.day = parseInt(diffSec / 86400);
      diffSec = diffSec % 86400;
      let hour = parseInt(diffSec / 3600);
      if (hour < 10) {
        this.hour = "0" + hour
      } else {
        this.hour = hour;
      }
      diffSec = diffSec % 3600;
      let minute = parseInt(diffSec / 60);
      if (minute < 10) {
        this.minute = "0" + minute
      } else {
        this.minute = minute;
      }
      let second = diffSec % 60;
      if (second < 10) {
        this.second = "0" + second
      } else {
        this.second = second;
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home {
  min-height: 798px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("@/assets/images/bg.jpg") no-repeat center bottom /cover;

  .icon-sound-box {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
    width: 60px;
    height: 60px;
    cursor: pointer;

    .icon-sound {
      width: 100%;
      height: 100%;
    }

    .icon-sound:active {
      background: none;
    }
  }

  .icon-sound-box:active {
    background: none;
  }

  //.img-bg {
  //  height: 100vh;
  //  position: fixed;
  //  top: 0;
  //  bottom: 0;
  //  z-index: 0;
  //}

  .page-inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    width: 560px;
  }

  .des {
    margin-top: -20px;
    width: 682px;
  }

  .time {
    width: 780px;
    height: 302px;
    background: url("@/assets/images/time-bg.png") no-repeat center center /100% 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .before {
    background-image: none;
  }

  .time-before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: url("@/assets/images/before.png") no-repeat center center /100% 100%;
    animation: scale 1.5s linear infinite;
  }

  .hidden {
    height: 0;
    width: 0;
    opacity: 0;
    transition: 0.5s;
  }

  @keyframes scale {
    0% {
      transform: translate(-50%, -50%) scale(0.95);
    }
    50% {
      transform: translate(-50%, -50%) scale(1.05);
    }
    100% {
      transform: translate(-50%, -50%) scale(0.95);
    }
  }

  .border {
    border-radius: 20px;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;

    span {
      position: absolute;
      display: block;
    }

    span:nth-child(1) {
      top: 0;
      left: 0;
      width: 100%;
      height: 6px;
      animation: animate1 2s linear infinite;
    }

    span:nth-child(2) {
      top: -100%;
      right: 0;
      width: 6px;
      height: 100%;
      animation: animate2 2s linear infinite;
      animation-delay: .5s;
    }

    span:nth-child(3) {
      bottom: 0;
      right: 0;
      width: 100%;
      height: 6px;
      animation: animate3 2s linear infinite;
      animation-delay: 1s;
    }

    span:nth-child(4) {
      bottom: -100%;
      left: 0;
      width: 6px;
      height: 100%;
      animation: animate4 2s linear infinite;
      animation-delay: 1.5s;
    }
  }

  //.border:nth-child(1) {
  //  filter: hue-rotate(270deg);
  //}
  .time-border {
    width: 695px;
    height: 250px;

    span:nth-child(1) {
      background: linear-gradient(90deg, transparent, #F403F0);
    }

    span:nth-child(2) {
      background: linear-gradient(180deg, transparent, #F403F0);
    }

    span:nth-child(3) {
      background: linear-gradient(270deg, transparent, #F403F0);
    }

    span:nth-child(4) {
      background: linear-gradient(360deg, transparent, #F403F0);
    }
  }

  .time-border2 {
    width: 665px;
    height: 220px;

    span:nth-child(1) {
      background: linear-gradient(90deg, transparent, #F4DC03);
    }

    span:nth-child(2) {
      background: linear-gradient(180deg, transparent, #F4DC03);
    }

    span:nth-child(3) {
      background: linear-gradient(270deg, transparent, #F4DC03);
    }

    span:nth-child(4) {
      background: linear-gradient(360deg, transparent, #F4DC03);
    }
  }

  .time-border3 {
    width: 635px;
    height: 190px;

    span:nth-child(1) {
      background: linear-gradient(90deg, transparent, #03e9f4);
    }

    span:nth-child(2) {
      background: linear-gradient(180deg, transparent, #03e9f4);
    }

    span:nth-child(3) {
      background: linear-gradient(270deg, transparent, #03e9f4);
    }

    span:nth-child(4) {
      background: linear-gradient(360deg, transparent, #03e9f4);
    }
  }


  .time-wp {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("@/assets/images/count-bg.png") no-repeat center center /100% 100%;

    .text {
      font-size: 44px;
      color: #6df9f3;
      text-shadow: 3px 3px 0 #781cdb,
      -3px -3px 0 #781cdb,
      3px -3px 0 #781cdb,
      -3px 3px 0 #781cdb,
      3px 3px 20px #5db6f9,
      -3px -3px 20px #5db6f9,
      3px -3px 20px #5db6f9,
      -3px 0.3rem 20px #5db6f9;
      font-weight: bold;
    }
  }
  .gap{
    background: transparent;
    width: 46px;
    .text{
      font-size: 28px;
    }
  }
  .gap1{
    background: transparent;
    width: 46px;
    text{
      font-size: 40px;
    }
  }

}

@keyframes animate1 {
  0% {
    left: -100%
  }

  50%, 100% {
    left: 100%
  }
}

@keyframes animate2 {
  0% {
    top: -100%
  }

  50%, 100% {
    top: 100%
  }
}

@keyframes animate3 {
  0% {
    right: -100%
  }

  50%, 100% {
    right: 100%
  }
}

@keyframes animate4 {
  0% {
    bottom: -100%
  }

  50%, 100% {
    bottom: 100%
  }
}

</style>
